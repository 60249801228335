// import { useEffect } from "react"

import { useRouter } from "next/router"
import { Portal } from "react-portal"
import { useToasts } from "hooks/ui/useToasts"
import { Head, Toast } from "components"
import styled from "styled-components"
import { Toast as ToastType } from "types/ui"
// import { Footer, Navbar } from "components"

const BaseLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;

  & li > button {
    font-family: var(--font-primary);
  }
`
const BaseLayoutContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ToastListWrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  transition: transform: 0.4s ease-in-out;
  z-index: var(--z-index-navbar);
  display: flex;
  flex-direction: column;
`

const ToastList = () => {
  const router = useRouter()
  const [toastList, handlers] = useToasts()
  if (!toastList?.length) return null

  const navigate = (path: string) => router.push(path)

  return (
    <Portal>
      <ToastListWrapper>
        {toastList?.map((toast: ToastType) => (
          <Toast
            key={toast.id}
            toast={toast}
            removeToast={() => handlers.removeUiToast(toast.id)}
            navigate={navigate}
          />
        ))}
      </ToastListWrapper>
    </Portal>
  )
}

export default function BaseLayout({ children, ...props }) {
  // const router = useRouter()

  // useEffect(() => {
  //   router.events.on("routeChangeComplete", () => {
  //     window.scroll({
  //       top: 0,
  //       left: 0,
  //     })
  //   })
  // }, [])

  return (
    <>
      <Head />
      <BaseLayoutWrapper>
        {/* <Navbar /> */}
        <BaseLayoutContent {...props}>
          <>{children}</>
        </BaseLayoutContent>
        <ToastList />
        {/* <Footer /> */}
      </BaseLayoutWrapper>
    </>
  )
}

// const GlobalStyle = createGlobalStyle`
//   /* Global SASS styles are here */

//   & ul > li > button {
//     background: transparent;
//     border: none;
//     color: black;
//     font-family: var(--font-primary);
//     transition: all 0.2s ease;
//   }

//   /* All buttons inside <li> inside <ul> should be treated like links */
//   & ul {
//     & > li {
//       & > button {
//         background: transparent;
//         border: none;
//         color: black;
//         font-family: var(--font-primary);
//         transition: all 0.2s ease;
//       }

//       &:hover {
//         & > button {
//           color: var(--color-highlight);
//         }
//       }
//     }
//   }
// `

// const LayoutWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh;
//   width: 100vw;
//   max-width: 100vw;

//   & .router {
//     height: 100%;
//     flex: 1;
//   }
// `
// const StyledMain = styled.main`
// flex: 1;
// min-height: 500px;
// display: flex;
// flex-direction: column;
// `

// const title = "Velup.io"
// const description = "Where education professionals source their development"
// const logo = "/assets/images/velup_blue_logo_192x192.png"

// export default function Layout({ children, ...props }: { children: React.ReactNode }) {
//   const router = useRouter()

//   useEffect(() => {
//     router.events.on("routeChangeComplete", () => {
//       window.scroll({
//         top: 0,
//         left: 0,
//       })
//     })
//   }, [])

//   return (
//     <>
//       <Head>
//         <title>{title}</title>
//         <meta name="description" content={description} />
//         <meta name="image" content={logo} />
//         <link rel="icon" href={logo} />
//       </Head>
//       <GlobalStyle />
//       {/* <WithAuth /> */}
//       <LayoutWrapper>
//         {/* <ErrorBoundary> */}
//         <>
//           <NavbarUnified />
//           <StyledMain {...props}>{children}</StyledMain>
//           <Footer />
//         </>
//         {/* </ErrorBoundary> */}
//       </LayoutWrapper>
//       <ToastList />
//     </>
//   )
// }
