import styled from "styled-components"
import { Footer, Navbar } from "components"
import BaseLayout from "../Base/Base"

const CoreLayoutContent = styled.div`
  flex: 1;
  min-height: 500px;
  display: flex;
  flex-direction: column;
`

export default function CoreLayout({ children, ...props }) {
  return (
    <BaseLayout>
      <Navbar />
      <CoreLayoutContent {...props}>
        <>{children}</>
      </CoreLayoutContent>
      <Footer />
    </BaseLayout>
  )
}
