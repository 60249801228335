import { createSelector } from "reselect"
import initialState, { StateShape } from "models/initialState"
import { SearchAction, SearchActionType, SearchFilterState, ActiveSearchFilters } from "types/search"
import { AuthAction, AuthActionType } from "types/auth"

/**
 * SEARCH REDUCER
 */
export default function searchReducer(state = initialState.search, action: SearchAction | AuthAction) {
  switch (action.type) {
    case SearchActionType.SET_USER_HAS_CLICKED_SEARCH:
      return {
        ...state,
        userHasClickedSearch: true,
      }
    case SearchActionType.SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.searchType,
      }
    case SearchActionType.SET_LAST_USER_SEARCH_QUERY:
      return {
        ...state,
        lastUserQuery: action.searchQuery,
      }
    case SearchActionType.SET_LAST_EVENT_SEARCH_QUERY:
      return {
        ...state,
        lastEventQuery: action.searchQuery,
      }
    case SearchActionType.SET_LAST_PROVIDER_SEARCH_QUERY:
      return {
        ...state,
        lastProviderQuery: action.searchQuery,
      }
    case SearchActionType.RESET_LIBRARY_SEARCH_STATE:
      return {
        ...state,
        filtersHaveChanged: true,
        userHasClickedSearch: false,
        date: initialState.search.date,
        reviews: initialState.search.reviews,
        rating: initialState.search.rating,
        top: initialState.search.top,
        location: initialState.search.location,
        price: initialState.search.price,
        // bookmarked: initialState.search.bookmarked,
      }
    case SearchActionType.SET_LIBRARY_SEARCH_STATE:
      // we're updating a nested object according to the filter section that needs updating
      // example action ({
      //    type: SET_LIBRARY_SEARCH_STATE,
      //    slice: "date",
      //    key: "start",
      //    value: "03-17-2020"
      // })
      // would update state.library.date.start to be "03-17-2020"
      return {
        ...state,
        filtersHaveChanged: true,
        userHasClickedSearch: false,
        [action.slice]: {
          ...state[action.slice],
          [action.key]: action.value,
        },
      }
    case SearchActionType.CLEAR_SEARCH_FILTER:
      return {
        ...state,
        filtersHaveChanged: true,
        userHasClickedSearch: false,
        [action.slice]: {
          ...initialState.search[action.slice],
        },
      }
    case AuthActionType.SIGN_USER_OUT:
      return initialState.search
    default:
      return state
  }
}

/**
 * SEARCH ACTIONS
 */
export type UpdateLibrarySearchFilterParams = { slice: string; key: string; value: any }
const updateLibrarySearchFilter = ({ slice, key, value }: UpdateLibrarySearchFilterParams) => ({
  type: SearchActionType.SET_LIBRARY_SEARCH_STATE,
  slice,
  key,
  value,
})
const clearLibrarySearchFilter = ({ slice }: { slice: string }) => ({
  type: SearchActionType.CLEAR_SEARCH_FILTER,
  slice,
})
const clearLibrarySearchFilters = () => ({ type: SearchActionType.RESET_LIBRARY_SEARCH_STATE })

const setSearchType = (searchType: string) => ({ type: SearchActionType.SET_SEARCH_TYPE, searchType })

const setLastUserSearchQuery = ({ searchQuery }: { searchQuery: string }) => ({
  type: SearchActionType.SET_LAST_USER_SEARCH_QUERY,
  searchQuery,
})
const setLastEventSearchQuery = ({ searchQuery }: { searchQuery: string }) => ({
  type: SearchActionType.SET_LAST_EVENT_SEARCH_QUERY,
  searchQuery,
})
const setLastProviderSearchQuery = ({ searchQuery }: { searchQuery: string }) => ({
  type: SearchActionType.SET_LAST_PROVIDER_SEARCH_QUERY,
  searchQuery,
})

const setUserHasClickedSearch = () => ({ type: SearchActionType.SET_USER_HAS_CLICKED_SEARCH })

export const Actions = {
  setSearchType,
  updateLibrarySearchFilter,
  clearLibrarySearchFilter,
  clearLibrarySearchFilters,
  setLastUserSearchQuery,
  setLastEventSearchQuery,
  setLastProviderSearchQuery,
  setUserHasClickedSearch,
}

/**
 * SEARCH SELECTORS
 */
const getDateFilters = (state: StateShape) => state.search.date
const getReviewsFilters = (state: StateShape) => state.search.reviews
const getRatingFilters = (state: StateShape) => state.search.rating
const getTopFilters = (state: StateShape) => state.search.top
const getLocationFilters = (state: StateShape) => state.search.location
const getPriceFilters = (state: StateShape) => state.search.price
const getFocusFilters = (state: StateShape) => state.search.focus
const getTopUserFilters = (state: StateShape) => state.search.topUsers
// const getBookmarkFilters = state => state.library.bookmarked

export const selectFilterState = createSelector(
  [
    getDateFilters,
    getReviewsFilters,
    getRatingFilters,
    getTopFilters,
    getLocationFilters,
    getPriceFilters,
    // getBookmarkFilters,
  ],
  (date, reviews, rating, top, location, price): SearchFilterState => ({
    date,
    reviews,
    rating,
    top,
    location,
    price,
  })
)

export const selectProviderFilterState = createSelector([getFocusFilters, getLocationFilters], (focus, location) => ({
  location,
  focus,
}))

export const selectUserFilterState = createSelector([getTopUserFilters, getLocationFilters], (topUser, location) => ({
  topUser,
  location,
}))

// convert something like Non Bookmarked Events into non_bookmarked_events
const underscoreAndLowercaseKey = (key: string): string => key.split(" ").join("_").toLowerCase()

const normalizeSingleLibraryFilter = (activeFilters: ActiveSearchFilters, key: string) => {
  const slice = activeFilters[key]
  const ret = {}
  if (slice && Object.keys(slice)) {
    Object.keys(slice).forEach((k) => {
      // concatenate the key (like "date") with k (like "start")
      const newKey = key + "_" + underscoreAndLowercaseKey(k)
      // add the value onto ret
      ret[newKey] = slice[k]
    })
  } else {
    return null
  }
  return ret
}

/**
 * Take in an object with keys - date, reviews, rating, top, location, price, bookmarked
 * and object values for each with potentially multiple keys
 *
 */
const normalizeActiveLibraryFilters = (activeFilters: ActiveSearchFilters) => {
  let ret = {}

  const keys = Object.keys(activeFilters)
  const normalizedResults = keys.map((key) => normalizeSingleLibraryFilter(activeFilters, key))

  normalizedResults.forEach((item) => {
    if (item) ret = { ...ret, ...item }
  })

  return ret
}

/**
 * Loop over all keys and values in the search filter state
 * and select only the ones where the value is truthy
 *
 * @param {Object} filterState
 * @returns - object with normalized results
 */
const getOnlyActiveFilters = (filterState: ActiveSearchFilters) => {
  const ret = {}

  Object.entries(filterState).forEach(([key, item]) => {
    ret[key] = {}

    Object.keys(item).forEach((k) => {
      // only keep if item value is actively selected
      if (item[k]) {
        ret[key][k] = item[k]
      }
    })
  })

  return normalizeActiveLibraryFilters(ret)
}

export const selectActiveEventFilters = createSelector([selectFilterState], (filterState) =>
  getOnlyActiveFilters(filterState)
)

export const selectActiveProviderFilters = createSelector([selectProviderFilterState], (filterState) =>
  getOnlyActiveFilters(filterState)
)

export const selectActiveUserFilters = createSelector([selectUserFilterState], (filterState) =>
  getOnlyActiveFilters(filterState)
)

// import initialState from "models/initialState"
// import apiClient from "services/apiClient"
// import { createSelector } from "reselect"
// import { ThunkAction } from "redux-thunk"
// import apiClient, { ApiClientCallbackResponse } from "services/apiClient"

// import initialState, { StateShape } from "models/initialState"
// import { ID, Error, ImageCreate, Location, Slug, Tag, Timestamp } from "models/utils"
// import { UiActionType, GenericUiAction, Actions as uiActions } from "models/ui"
// import { selectAuthedUserId, GenericAuthAction, AuthActionType, UserBase, User, UserID } from "models/auth"
// import { EventFull, EventID } from "models/events"
// import { ProviderCore } from "models/providers"
// import { StripeID, StripeAccount } from "models/payments"
//
// import { formatFiltersForBackend } from "utils/search"

// import { Error, Timestamp } from "types/common"
// import { AnyAction } from "redux"

/**
 * SEARCH TYPES CONSTANTS
 *
 */

// export interface EventFromSearch extends EventFull {
//   location_name?: string
// }
// export interface ProviderFromSearch extends Omit<ProviderCore, "details_submitted"> {}
// export interface UserFromSearch extends UserBase {
//   full_name?: string
//   bio?: string
//   image?: string
//   imgix_src?: string
// }

// export type SearchResult = EventFromSearch | ProviderFromSearch | UserFromSearch

// export type TopSearchParams = { selected?: any }
// export type LocationSearchParams = {
//   mi_radius?: string | number | null
//   center_long?: string | number | null
//   center_lat?: string | number | null
//   google_location?: string | number | null
// }
// export type PriceSearchParams = { selected?: string }
// export type FocusSearchParams = { selected?: string }
// export type TopUsersSearchParams = { selected?: string }
// export type RatingsSearchParams = { selected?: string | number }
// export type ReviewsSearchParams = { has_reviews?: boolean }
// export type DateSearchParams = { start?: Timestamp; end?: Timestamp }
// export interface SearchFilterState {
//   date: DateSearchParams
//   reviews: ReviewsSearchParams
//   rating: RatingsSearchParams
//   top: TopSearchParams
//   location: LocationSearchParams
//   price: PriceSearchParams
//   focus?: FocusSearchParams
//   topUsers?: TopUsersSearchParams
// }
// export interface ActiveSearchFilters {
//   date?: DateSearchParams
//   reviews?: ReviewsSearchParams
//   rating?: RatingsSearchParams
//   top?: TopSearchParams
//   location?: LocationSearchParams
//   price?: PriceSearchParams
//   focus?: FocusSearchParams
//   topUsers?: TopUsersSearchParams
// }

// export interface SearchState extends SearchFilterState {
//   searchType: string
//   lastUserQuery: string | null
//   lastEventQuery: string | null
//   lastProviderQuery: string | null
//   // userResults: UserFromSearch[]
//   // providerResults: ProviderFromSearch[]
//   // eventResults: EventFromSearch[]
//   // results: UserFromSearch[] | ProviderFromSearch[] | EventFromSearch[]
//   filtersHaveChanged: true
//   userHasClickedSearch: true
//   // isLoading: false
//   // error: Error
// }
// export interface FilterParamsForBackendSearch {
//   location_mi_radius?: string | null
//   location_center_lat?: string | null
//   location_center_long?: string | null
//   location_google_location?: string | null
//   date_start?: string | null
//   date_end?: string | null
//   rating_selected?: string | null
//   top_selected?: string | null
//   price_selected?: string | null
// }

// export enum SearchActionType {
//   RESET_LIBRARY_SEARCH_STATE = "@@search/RESET_LIBRARY_SEARCH_STATE",
//   SET_LIBRARY_SEARCH_STATE = "@@search/SET_LIBRARY_SEARCH_STATE",
//   //  SET_LIBRARY_SEARCH_FILTER_ICON = "@@search/SET_SEARCH_FILTER_ICON"
//   SET_SEARCH_TYPE = "@@search/SET_SEARCH_TYPE",
//   SET_USER_HAS_CLICKED_SEARCH = "@@search/SET_USER_HAS_CLICKED_SEARCH",
//   CLEAR_SEARCH_FILTER = "@@search/CLEAR_SEARCH_FILTER",

//   SET_LAST_PROVIDER_SEARCH_QUERY = "@@search/SET_LAST_PROVIDER_SEARCH_QUERY",
//   SET_LAST_EVENT_SEARCH_QUERY = "@@search/SET_LAST_EVENT_SEARCH_QUERY",
//   SET_LAST_USER_SEARCH_QUERY = "@@search/SET_LAST_USER_SEARCH_QUERY",

//   // EVENT_SEARCH_REQUEST = "@@search/EVENT_SEARCH_REQUEST",
//   // EVENT_SEARCH_REQUEST_SUCCESS = "@@search/EVENT_SEARCH_REQUEST_SUCCESS",
//   // EVENT_SEARCH_REQUEST_FAILURE = "@@search/EVENT_SEARCH_REQUEST_FAILURE",

//   // PROVIDER_SEARCH_REQUEST = "@@search/PROVIDER_SEARCH_REQUEST",
//   // PROVIDER_SEARCH_REQUEST_SUCCESS = "@@search/PROVIDER_SEARCH_REQUEST_SUCCESS",
//   // PROVIDER_SEARCH_REQUEST_FAILURE = "@@search/PROVIDER_SEARCH_REQUEST_FAILURE",

//   // USER_SEARCH_REQUEST = "@@search/USER_SEARCH_REQUEST",
//   // USER_SEARCH_REQUEST_SUCCESS = "@@search/USER_SEARCH_REQUEST_SUCCESS",
//   // USER_SEARCH_REQUEST_FAILURE = "@@search/USER_SEARCH_REQUEST_FAILURE",
// }

// export interface IResetLibrarySearchState {
//   type: SearchActionType.RESET_LIBRARY_SEARCH_STATE
// }
// export interface ISetLibrarySearchState {
//   type: SearchActionType.SET_LIBRARY_SEARCH_STATE
//   slice: string
//   key: string
//   value: any
// }
// export interface ISetSearchType {
//   type: SearchActionType.SET_SEARCH_TYPE
//   searchType: string
// }
// export interface ISetUserHasClickedSearch {
//   type: SearchActionType.SET_USER_HAS_CLICKED_SEARCH
// }
// export interface IClearSearchFilter {
//   type: SearchActionType.CLEAR_SEARCH_FILTER
//   slice: string
// }
// export type GenericSearchAction =
//   | IResetLibrarySearchState
//   | ISetLibrarySearchState
//   | ISetSearchType
//   | ISetUserHasClickedSearch
//   | IClearSearchFilter

// // SET LAST RESULT QUERY
// export interface ISetLastProviderSearchQuery {
//   type: SearchActionType.SET_LAST_PROVIDER_SEARCH_QUERY
//   searchQuery: string
// }
// export interface ISetLastEventSearchQuery {
//   type: SearchActionType.SET_LAST_EVENT_SEARCH_QUERY
//   searchQuery: string
// }
// export interface ISetLastUserSearchQuery {
//   type: SearchActionType.SET_LAST_USER_SEARCH_QUERY
//   searchQuery: string
// }
// export type SetLastSearchAction = ISetLastProviderSearchQuery | ISetLastEventSearchQuery | ISetLastUserSearchQuery

// EVENT SEARCH
// export interface IEventSearchRequest {
//   type: SearchActionType.EVENT_SEARCH_REQUEST
// }
// export interface IEventSearchRequestSuccess {
//   type: SearchActionType.EVENT_SEARCH_REQUEST_SUCCESS
//   data: EventFromSearch[]
// }
// export interface IEventSearchRequestFailure {
//   type: SearchActionType.EVENT_SEARCH_REQUEST_FAILURE
//   error: Error
// }
// export type EventSearchAction = IEventSearchRequest | IEventSearchRequestSuccess | IEventSearchRequestFailure

// // PROVIDER SEARCH
// export interface IProviderSearchRequest {
//   type: SearchActionType.PROVIDER_SEARCH_REQUEST
// }
// export interface IProviderSearchRequestSuccess {
//   type: SearchActionType.PROVIDER_SEARCH_REQUEST_SUCCESS
//   data: ProviderFromSearch[]
// }
// export interface IProviderSearchRequestFailure {
//   type: SearchActionType.PROVIDER_SEARCH_REQUEST_FAILURE
//   error: Error
// }
// export type ProviderSearchAction =
//   | IProviderSearchRequest
//   | IProviderSearchRequestSuccess
//   | IProviderSearchRequestFailure

// // USER SEARCH
// export interface IUserSearchRequest {
//   type: SearchActionType.USER_SEARCH_REQUEST
// }
// export interface IUserSearchRequestSuccess {
//   type: SearchActionType.USER_SEARCH_REQUEST_SUCCESS
//   data: UserFromSearch[]
// }
// export interface IUserSearchRequestFailure {
//   type: SearchActionType.USER_SEARCH_REQUEST_FAILURE
//   error: Error
// }
// export type UserSearchAction = IUserSearchRequest | IUserSearchRequestSuccess | IUserSearchRequestFailure

// export type SearchAction =
//   | GenericSearchAction
//   | SetLastSearchAction
// | EventSearchAction
// | ProviderSearchAction
// | UserSearchAction

// /**
//  * SEARCH REDUCER
//  */
// export default function searchReducer(state = initialState.search, action: SearchAction) {
//   switch (action.type) {
//     case SearchActionType.SET_USER_HAS_CLICKED_SEARCH:
//       return {
//         ...state,
//         userHasClickedSearch: true,
//       }
//     case SearchActionType.SET_SEARCH_TYPE:
//       return {
//         ...state,
//         searchType: action.searchType,
//       }
//     case SearchActionType.SET_LAST_USER_SEARCH_QUERY:
//       return {
//         ...state,
//         lastUserQuery: action.searchQuery,
//       }
//     case SearchActionType.SET_LAST_EVENT_SEARCH_QUERY:
//       return {
//         ...state,
//         lastEventQuery: action.searchQuery,
//       }
//     case SearchActionType.SET_LAST_PROVIDER_SEARCH_QUERY:
//       return {
//         ...state,
//         lastProviderQuery: action.searchQuery,
//       }
//     // case SearchActionType.EVENT_SEARCH_REQUEST:
//     //   return {
//     //     ...state,
//     //     isLoading: true,
//     //   }
//     // case SearchActionType.EVENT_SEARCH_REQUEST_SUCCESS:
//     //   return {
//     //     ...state,
//     //     isLoading: false,
//     //     error: null,
//     //     eventResults: action.data || [],
//     //     filtersHaveChanged: false,
//     //   }
//     // case SearchActionType.EVENT_SEARCH_REQUEST_FAILURE:
//     //   return {
//     //     ...state,
//     //     isLoading: false,
//     //     error: action.error,
//     //     eventResults: [],
//     //   }
//     // case SearchActionType.PROVIDER_SEARCH_REQUEST:
//     //   return {
//     //     ...state,
//     //     isLoading: true,
//     //   }
//     // case SearchActionType.PROVIDER_SEARCH_REQUEST_SUCCESS:
//     //   return {
//     //     ...state,
//     //     isLoading: false,
//     //     error: null,
//     //     providerResults: action.data || [],
//     //     filtersHaveChanged: false,
//     //   }
//     // case SearchActionType.PROVIDER_SEARCH_REQUEST_FAILURE:
//     //   return {
//     //     ...state,
//     //     isLoading: false,
//     //     error: action.error,
//     //     providerResults: [],
//     //   }
//     // case SearchActionType.USER_SEARCH_REQUEST:
//     //   return {
//     //     ...state,
//     //     isLoading: true,
//     //     filtersHaveChanged: false,
//     //   }
//     // case SearchActionType.USER_SEARCH_REQUEST_SUCCESS:
//     //   return {
//     //     ...state,
//     //     isLoading: false,
//     //     userResults: action.data,
//     //   }
//     // case SearchActionType.USER_SEARCH_REQUEST_FAILURE:
//     //   return {
//     //     ...state,
//     //     isLoading: false,
//     //     userResults: [],
//     //   }
//     case SearchActionType.RESET_LIBRARY_SEARCH_STATE:
//       return {
//         ...state,
//         filtersHaveChanged: true,
//         userHasClickedSearch: false,
//         date: initialState.search.date,
//         reviews: initialState.search.reviews,
//         rating: initialState.search.rating,
//         top: initialState.search.top,
//         location: initialState.search.location,
//         price: initialState.search.price,
//         // bookmarked: initialState.search.bookmarked,
//       }
//     case SearchActionType.SET_LIBRARY_SEARCH_STATE:
//       // we're updating a nested object according to the filter section that needs updating
//       // example action ({
//       //    type: SET_LIBRARY_SEARCH_STATE,
//       //    slice: "date",
//       //    key: "start",
//       //    value: "03-17-2020"
//       // })
//       // would update state.library.date.start to be "03-17-2020"
//       return {
//         ...state,
//         filtersHaveChanged: true,
//         userHasClickedSearch: false,
//         [action.slice]: {
//           ...state[action.slice],
//           [action.key]: action.value,
//         },
//       }
//     case SearchActionType.CLEAR_SEARCH_FILTER:
//       return {
//         ...state,
//         filtersHaveChanged: true,
//         userHasClickedSearch: false,
//         [action.slice]: {
//           ...initialState.search[action.slice],
//         },
//       }
//     // case AuthActionType.SIGN_USER_OUT:
//     //   return initialState.search
//     default:
//       return state
//   }
// }

// /**
//  * SEARCH ACTIONS
//  */
// export type UpdateLibrarySearchFilterParams = { slice: string; key: string; value: any }
// const updateLibrarySearchFilter = ({ slice, key, value }: UpdateLibrarySearchFilterParams) => ({
//   type: SearchActionType.SET_LIBRARY_SEARCH_STATE,
//   slice,
//   key,
//   value,
// })
// const clearLibrarySearchFilter = ({ slice }: { slice: string }) => ({
//   type: SearchActionType.CLEAR_SEARCH_FILTER,
//   slice,
// })
// const clearLibrarySearchFilters = () => ({ type: SearchActionType.RESET_LIBRARY_SEARCH_STATE })

// const setSearchType = (searchType: string) => ({ type: SearchActionType.SET_SEARCH_TYPE, searchType })

// const setLastUserSearchQuery = ({ searchQuery }: { searchQuery: string }) => ({
//   type: SearchActionType.SET_LAST_USER_SEARCH_QUERY,
//   searchQuery,
// })
// const setLastEventSearchQuery = ({ searchQuery }: { searchQuery: string }) => ({
//   type: SearchActionType.SET_LAST_EVENT_SEARCH_QUERY,
//   searchQuery,
// })
// const setLastProviderSearchQuery = ({ searchQuery }: { searchQuery: string }) => ({
//   type: SearchActionType.SET_LAST_PROVIDER_SEARCH_QUERY,
//   searchQuery,
// })

// const setUserHasClickedSearch = () => ({ type: SearchActionType.SET_USER_HAS_CLICKED_SEARCH })

// export type SearchRequestParams = { searchQuery: string; searchFilters?: FilterParamsForBackendSearch }
// export type UserSearchRequestResult = ThunkAction<
//   Promise<ApiClientCallbackResponse>,
//   StateShape,
//   void,
//   UserSearchAction | SetLastSearchAction
// >
// const userSearchRequest = ({ searchQuery, searchFilters }: SearchRequestParams): UserSearchRequestResult => {
//   return async (dispatch) => {
//     const params = { q: searchQuery, ...formatFiltersForBackend(searchFilters) }

//     dispatch(Actions.setLastUserSearchQuery({ searchQuery }) as SetLastSearchAction)

//     return dispatch(
//       apiClient({
//         url: `/search/users/`,
//         method: "GET",
//         types: {
//           REQUEST: SearchActionType.USER_SEARCH_REQUEST,
//           SUCCESS: SearchActionType.USER_SEARCH_REQUEST_SUCCESS,
//           FAILURE: SearchActionType.USER_SEARCH_REQUEST_FAILURE,
//         },
//         options: {
//           data: {},
//           params,
//         },
//         onSuccess: (res) => ({ success: true, res, data: res.data?.users || [], status: 200 }),
//         onFailure: (res) => ({ success: false, error: res.error, res, status: 400 }),
//       })
//     )
//   }
// }

// export type ProviderSearchRequestResult = ThunkAction<
//   Promise<ApiClientCallbackResponse>,
//   StateShape,
//   void,
//   ProviderSearchAction | SetLastSearchAction
//   // AnyAction
// >
// const providerSearchRequest = ({ searchQuery, searchFilters }: SearchRequestParams): ProviderSearchRequestResult => {
//   return async (dispatch) => {
//     // use selector to get state
//     // const state = getState()
//     // const filters = selectActiveProviderFilters(state)
//     const params = { q: searchQuery, ...formatFiltersForBackend(searchFilters) }

//     dispatch(Actions.setLastProviderSearchQuery({ searchQuery }) as SetLastSearchAction)

//     return dispatch(
//       apiClient({
//         url: `/search/providers/`,
//         method: "GET",
//         types: {
//           REQUEST: SearchActionType.PROVIDER_SEARCH_REQUEST,
//           SUCCESS: SearchActionType.PROVIDER_SEARCH_REQUEST_SUCCESS,
//           FAILURE: SearchActionType.PROVIDER_SEARCH_REQUEST_FAILURE,
//         },
//         options: {
//           data: {},
//           params,
//         },
//         onSuccess: (res) => ({ success: true, res, data: res.data?.providers || [], status: 200 }),
//         onFailure: (res) => ({ success: false, error: res.error, res, status: 400 }),
//       })
//     )
//   }
// }

// export type EventSearchRequestResult = ThunkAction<
//   Promise<ApiClientCallbackResponse>,
//   StateShape,
//   void,
//   EventSearchAction | SetLastSearchAction
// >
// const eventSearchRequest = ({ searchQuery, searchFilters }: SearchRequestParams): EventSearchRequestResult => {
//   return async (dispatch) => {
//     // use selector to get state
//     // const state = getState()
//     // const filters = selectActiveEventFilters(state)
//     // console.log('filters', filters)

//     const params = {
//       q: searchQuery,
//       ...formatFiltersForBackend(searchFilters),
//     }

//     dispatch(Actions.setLastEventSearchQuery({ searchQuery }) as SetLastSearchAction)

//     return dispatch(
//       apiClient({
//         url: `/search/events/`,
//         method: "GET",
//         types: {
//           REQUEST: SearchActionType.EVENT_SEARCH_REQUEST,
//           SUCCESS: SearchActionType.EVENT_SEARCH_REQUEST_SUCCESS,
//           FAILURE: SearchActionType.EVENT_SEARCH_REQUEST_FAILURE,
//         },
//         options: {
//           data: {},
//           params,
//         },
//         onSuccess: (res) => ({ success: true, data: res.data, status: res.status }),
//         onFailure: (res) => ({ success: false, error: res.error, status: res.status }),
//       })
//     )
//   }
// }

// export const Actions = {
//   setSearchType,
//   updateLibrarySearchFilter,
//   clearLibrarySearchFilter,
//   clearLibrarySearchFilters,
//   setLastUserSearchQuery,
//   setLastEventSearchQuery,
//   setLastProviderSearchQuery,
//   setUserHasClickedSearch,
//   // providerSearchRequest,
//   // userSearchRequest,
//   // eventSearchRequest,
// }

// /**
//  * SEARCH SELECTORS
//  */
// const getDateFilters = (state: StateShape) => state.search.date
// const getReviewsFilters = (state: StateShape) => state.search.reviews
// const getRatingFilters = (state: StateShape) => state.search.rating
// const getTopFilters = (state: StateShape) => state.search.top
// const getLocationFilters = (state: StateShape) => state.search.location
// const getPriceFilters = (state: StateShape) => state.search.price
// const getFocusFilters = (state: StateShape) => state.search.focus
// const getTopUserFilters = (state: StateShape) => state.search.topUsers
// // const getBookmarkFilters = state => state.library.bookmarked

// export const selectFilterState = createSelector(
//   [
//     getDateFilters,
//     getReviewsFilters,
//     getRatingFilters,
//     getTopFilters,
//     getLocationFilters,
//     getPriceFilters,
//     // getBookmarkFilters,
//   ],
//   (date, reviews, rating, top, location, price): SearchFilterState => ({
//     date,
//     reviews,
//     rating,
//     top,
//     location,
//     price,
//   })
// )

// export const selectProviderFilterState = createSelector([getFocusFilters, getLocationFilters], (focus, location) => ({
//   location,
//   focus,
// }))

// export const selectUserFilterState = createSelector([getTopUserFilters, getLocationFilters], (topUser, location) => ({
//   topUser,
//   location,
// }))

// // convert something like Non Bookmarked Events into non_bookmarked_events
// const underscoreAndLowercaseKey = (key: string): string => key.split(" ").join("_").toLowerCase()

// const normalizeSingleLibraryFilter = (activeFilters: ActiveSearchFilters, key: string) => {
//   const slice = activeFilters[key]
//   const ret = {}
//   if (slice && Object.keys(slice)) {
//     Object.keys(slice).forEach((k) => {
//       // concatenate the key (like "date") with k (like "start")
//       const newKey = key + "_" + underscoreAndLowercaseKey(k)
//       // add the value onto ret
//       ret[newKey] = slice[k]
//     })
//   } else {
//     return null
//   }
//   return ret
// }

// /**
//  * Take in an object with keys - date, reviews, rating, top, location, price, bookmarked
//  * and object values for each with potentially multiple keys
//  *
//  */
// const normalizeActiveLibraryFilters = (activeFilters: ActiveSearchFilters) => {
//   let ret = {}

//   const keys = Object.keys(activeFilters)
//   const normalizedResults = keys.map((key) => normalizeSingleLibraryFilter(activeFilters, key))

//   normalizedResults.forEach((item) => {
//     if (item) ret = { ...ret, ...item }
//   })

//   return ret
// }

// /**
//  * Loop over all keys and values in the search filter state
//  * and select only the ones where the value is truthy
//  *
//  * @param {Object} filterState
//  * @returns - object with normalized results
//  */
// const getOnlyActiveFilters = (filterState: ActiveSearchFilters) => {
//   const ret = {}

//   Object.entries(filterState).forEach(([key, item]) => {
//     ret[key] = {}

//     Object.keys(item).forEach((k) => {
//       // only keep if item value is actively selected
//       if (item[k]) {
//         ret[key][k] = item[k]
//       }
//     })
//   })

//   return normalizeActiveLibraryFilters(ret)
// }

// export const selectActiveEventFilters = createSelector([selectFilterState], (filterState) =>
//   getOnlyActiveFilters(filterState)
// )

// export const selectActiveProviderFilters = createSelector([selectProviderFilterState], (filterState) =>
//   getOnlyActiveFilters(filterState)
// )

// export const selectActiveUserFilters = createSelector([selectUserFilterState], (filterState) =>
//   getOnlyActiveFilters(filterState)
// )
