import * as R from "ramda"
import { ThunkAction } from "redux-thunk"
import apiClient, { ApiClientCallbackResponse } from "services/apiClient"

import initialState, { StateShape } from "models/initialState"
import { Actions as uiActions } from "models/ui"

import { formatDateIsoFormat, isDateObject } from "utils/dates"
import { extractErrorMessages, makeUserFriendlyErrorMessage } from "utils/errors"

import { Timestamp } from "types/common"
import { AuthAction, AuthActionType } from "types/auth"
import { ToastType } from "types/ui"
import { FeedAction, FeedActionType } from "types/feed"

export default function feedReducer(state = initialState.feed, action: FeedAction | AuthAction) {
  switch (action.type) {
    case FeedActionType.FETCH_MAIN_FEED_FOR_USER:
      return {
        ...state,
        isLoading: true,
      }
    case FeedActionType.FETCH_MAIN_FEED_FOR_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        lastFetched: new Date().toISOString(),
        // initialized: true,
        data: {
          ...state.data,
          ...(R.indexBy(R.prop("id"), R.map(R.prop("activity_item"), action.data)) || {}),
        },
      }
    case FeedActionType.FETCH_MAIN_FEED_FOR_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        lastFetched: new Date().toISOString(),
        // initialized: true,
      }
    case FeedActionType.CLEAR_FEED_STATE:
      return initialState.feed
    case AuthActionType.SIGN_USER_OUT:
      return initialState.feed
    default:
      return state
  }
}

/**
 * FEED ACTIONS
 *
 */
const clearFeedState = () => ({ type: FeedActionType.CLEAR_FEED_STATE })

export type FetchMainFeedForUserResult = ThunkAction<Promise<ApiClientCallbackResponse>, StateShape, void, FeedAction>
const fetchMainFeedForUser = (
  startingDate: Timestamp = new Date(),
  pageChunkSize: number = 20
): FetchMainFeedForUserResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/feed/`,
        method: `GET`,
        types: {
          REQUEST: FeedActionType.FETCH_MAIN_FEED_FOR_USER,
          SUCCESS: FeedActionType.FETCH_MAIN_FEED_FOR_USER_SUCCESS,
          FAILURE: FeedActionType.FETCH_MAIN_FEED_FOR_USER_FAILURE,
        },
        options: {
          data: {},
          params: {
            starting_date: isDateObject(startingDate) ? formatDateIsoFormat(startingDate) : startingDate,
            page_chunk_size: pageChunkSize,
          },
        },
        onSuccess: (res) => {
          dispatch({
            type: FeedActionType.SET_HAS_NEXT_FOR_FEED,
            feed: "main",
            hasNext: Boolean(res?.data?.length === pageChunkSize),
          })

          return { success: true, status: res.status, data: res.data }
        },
        onFailure: (res) => {
          const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          const errorMessages = extractErrorMessages(error)

          errorMessages.forEach((error) => {
            const toast = {
              title: "Could not fetch feed.",
              contents: makeUserFriendlyErrorMessage(error),
              type: ToastType.DANGER,
            }
            dispatch(uiActions.addUiToast({ toast }))
          })

          return { success: false, status: res.status, error }
        },
      })
    )
  }
}

export const Actions = {
  clearFeedState,
  fetchMainFeedForUser,
}
