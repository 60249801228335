import { Timestamp } from "types/common"

export type TopSearchParams = { selected?: any }
export type LocationSearchParams = {
  mi_radius?: string | number | null
  center_long?: string | number | null
  center_lat?: string | number | null
  google_location?: string | number | null
}
export type PriceSearchParams = { selected?: string }
export type FocusSearchParams = { selected?: string }
export type TopUsersSearchParams = { selected?: string }
export type RatingsSearchParams = { selected?: string | number }
export type ReviewsSearchParams = { has_reviews?: boolean }
export type DateSearchParams = { start?: Timestamp; end?: Timestamp }
export interface SearchFilterState {
  date: DateSearchParams
  reviews: ReviewsSearchParams
  rating: RatingsSearchParams
  top: TopSearchParams
  location: LocationSearchParams
  price: PriceSearchParams
  focus?: FocusSearchParams
  topUsers?: TopUsersSearchParams
}
export interface ActiveSearchFilters {
  date?: DateSearchParams
  reviews?: ReviewsSearchParams
  rating?: RatingsSearchParams
  top?: TopSearchParams
  location?: LocationSearchParams
  price?: PriceSearchParams
  focus?: FocusSearchParams
  topUsers?: TopUsersSearchParams
}
export interface SearchState extends SearchFilterState {
  searchType: string
  lastUserQuery: string | null
  lastEventQuery: string | null
  lastProviderQuery: string | null
  filtersHaveChanged: true
  userHasClickedSearch: true
}
export interface FilterParamsForBackendSearch {
  location_mi_radius?: string | null
  location_center_lat?: string | null
  location_center_long?: string | null
  location_google_location?: string | null
  date_start?: string | null
  date_end?: string | null
  rating_selected?: string | null
  top_selected?: string | null
  price_selected?: string | null
}

export enum SearchActionType {
  RESET_LIBRARY_SEARCH_STATE = "@@search/RESET_LIBRARY_SEARCH_STATE",
  SET_LIBRARY_SEARCH_STATE = "@@search/SET_LIBRARY_SEARCH_STATE",

  SET_SEARCH_TYPE = "@@search/SET_SEARCH_TYPE",
  SET_USER_HAS_CLICKED_SEARCH = "@@search/SET_USER_HAS_CLICKED_SEARCH",
  CLEAR_SEARCH_FILTER = "@@search/CLEAR_SEARCH_FILTER",

  SET_LAST_PROVIDER_SEARCH_QUERY = "@@search/SET_LAST_PROVIDER_SEARCH_QUERY",
  SET_LAST_EVENT_SEARCH_QUERY = "@@search/SET_LAST_EVENT_SEARCH_QUERY",
  SET_LAST_USER_SEARCH_QUERY = "@@search/SET_LAST_USER_SEARCH_QUERY",
}

export interface IResetLibrarySearchState {
  type: SearchActionType.RESET_LIBRARY_SEARCH_STATE
}
export interface ISetLibrarySearchState {
  type: SearchActionType.SET_LIBRARY_SEARCH_STATE
  slice: string
  key: string
  value: any
}
export interface ISetSearchType {
  type: SearchActionType.SET_SEARCH_TYPE
  searchType: string
}
export interface ISetUserHasClickedSearch {
  type: SearchActionType.SET_USER_HAS_CLICKED_SEARCH
}
export interface IClearSearchFilter {
  type: SearchActionType.CLEAR_SEARCH_FILTER
  slice: string
}
export type GenericSearchAction =
  | IResetLibrarySearchState
  | ISetLibrarySearchState
  | ISetSearchType
  | ISetUserHasClickedSearch
  | IClearSearchFilter

// SET LAST RESULT QUERY
export interface ISetLastProviderSearchQuery {
  type: SearchActionType.SET_LAST_PROVIDER_SEARCH_QUERY
  searchQuery: string
}
export interface ISetLastEventSearchQuery {
  type: SearchActionType.SET_LAST_EVENT_SEARCH_QUERY
  searchQuery: string
}
export interface ISetLastUserSearchQuery {
  type: SearchActionType.SET_LAST_USER_SEARCH_QUERY
  searchQuery: string
}
export type SetLastSearchAction = ISetLastProviderSearchQuery | ISetLastEventSearchQuery | ISetLastUserSearchQuery

export type SearchAction = GenericSearchAction | SetLastSearchAction
