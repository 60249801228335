// import { useRef } from "react"
import { AppProps } from "next/app"
import { Provider as ReduxProvider } from "react-redux"
import { Provider as NextAuthProvider } from "next-auth/client"
// import { QueryClient, QueryClientProvider } from "react-query"
// import { Hydrate } from "react-query/hydration"
// import { ReactQueryDevtools } from "react-query/devtools"
import { Core as CoreLayout } from "layouts"
import { VelupPage } from "types/pages"
import { LoadingScreen } from "views"
import { useStore } from "models/store"
import { PersistGate } from "redux-persist/integration/react"

import "styles/fonts.css"
import "styles/defaults.css"
import "styles/variables.css"
import "styles/globals.css"

type VelupAppProps = Omit<AppProps, "Component"> & {
  Component: VelupPage
}

// const queryConfig = {
//   refetchAllOnWindowFocus: true,
//   retry: 0,
//   staleTime: 60 * 1000, // one hour
// }

export default function VelupApp({ Component, pageProps }: VelupAppProps) {
  // const queryClientRef = useRef(null)
  // if (!queryClientRef.current) {
  //   queryClientRef.current = new QueryClient()
  // }

  const { store } = useStore(pageProps.initialReduxState)
  const PageLayout = Component.Layout ? Component.Layout : CoreLayout

  return (
    <NextAuthProvider session={pageProps.session}>
      <ReduxProvider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={store.__persistor}>
          {/* <QueryClientProvider client={queryClientRef.current}> */}
          {/* <Hydrate state={pageProps.dehydratedState}> */}
          <PageLayout>
            <Component {...pageProps} />
          </PageLayout>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          {/* </Hydrate> */}
          {/* </QueryClientProvider> */}
        </PersistGate>
      </ReduxProvider>
    </NextAuthProvider>
  )
}
