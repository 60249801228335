import styled from "styled-components"
import BaseLayout from "../Base/Base"

const MinimalLayoutContent = styled.div`
  flex: 1;
  // min-height: 500px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

export default function MinimalLayout({ children, ...props }) {
  return (
    <BaseLayout>
      <MinimalLayoutContent {...props}>
        <>{children}</>
      </MinimalLayoutContent>
    </BaseLayout>
  )
}
