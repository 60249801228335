import { useMemo } from "react"
import { createStore, applyMiddleware, Reducer } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunkMiddleware from "redux-thunk"
import { persistStore, persistReducer } from "redux-persist"
import localForage from "localforage"
import rootReducer from "models/rootReducer"
import initialState from "models/initialState"

let store: any

const persistConfig = {
  key: "root",
  storage: localForage,
  blacklist: ["events", "providers"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer as Reducer)

function initStore(preloadedState: any = initialState) {
  return createStore(persistedReducer, preloadedState, composeWithDevTools(applyMiddleware(thunkMiddleware)))
}

export const initializeStore = (preloadedState: any) => {
  let _store = store ?? initStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store

  // Create the store once in the client
  if (!store) store = _store

  _store.__persistor = persistStore(_store)

  return _store
}

export function useStore(initialState: any) {
  const store = useMemo(() => initializeStore(initialState), [initialState])

  return { store }
}
