export interface WizardConfig {
  currentStep: string
  [key: string]: any
}
export interface WizardState {
  initialized: boolean
  feedOnboarding: WizardConfig
  eventsCreate: WizardConfig
  eventsHomeCreate: WizardConfig
  eventsUpdate: WizardConfig
  providersHomeCreate: WizardConfig
  providerToStripe: WizardConfig
}

export enum WizardActionType {
  SET_WIZARD_STEP = "@@wizard/SET_WIZARD_STEP",
  SELECT_WIZARD_ITEM = "@@wizard/SELECT_WIZARD_ITEM",
  UPDATE_WIZARD_ITEM = "@@wizard/UPDATE_WIZARD_ITEM",
  CLEAR_WIZARD_STATE = "@@wizard/CLEAR_WIZARD_STATE",
  SET_WIZARD_ERROR = "@@wizard/SET_WIZARD_ERROR",
  CLEAR_WIZARD_ERROR = "@@wizard/CLEAR_WIZARD_ERROR",
}

export interface IWizardActionForSlice {
  slice: string
}
export interface IClearWizardState extends IWizardActionForSlice {
  type: WizardActionType.CLEAR_WIZARD_STATE
}
export interface IWizardActionForStepInSlice extends IWizardActionForSlice {
  step: string
}
export interface ISetWizardStep extends IWizardActionForStepInSlice {
  type: WizardActionType.SET_WIZARD_STEP
}
export interface ISelectWizardItem extends IWizardActionForStepInSlice {
  type: WizardActionType.SELECT_WIZARD_ITEM
  name: string
  toggleValue: any
}
export interface IUpdateWizardItem extends IWizardActionForStepInSlice {
  type: WizardActionType.UPDATE_WIZARD_ITEM
  name: string
  value: any
}
export interface ISetWizardError extends IWizardActionForStepInSlice {
  type: WizardActionType.SET_WIZARD_ERROR
  error: string
}
export interface IClearWizardError extends IWizardActionForStepInSlice {
  type: WizardActionType.CLEAR_WIZARD_ERROR
}
export type WizardAction =
  | ISetWizardStep
  | ISelectWizardItem
  | IUpdateWizardItem
  | IClearWizardState
  | ISetWizardError
  | IClearWizardError
