import { ThunkAction } from "redux-thunk"
import initialState, { StateShape } from "models/initialState"
import { Actions as uiActions } from "models/ui"
import { isDefined } from "utils/parseUtils"
import { EMAIL_NEEDS_CONFIRMATION_MESSAGE } from "constants/errors"
import { extractErrorMessages, makeUserFriendlyErrorMessage } from "utils/errors"

// import { Error } from "types/common"
import { ToastType } from "types/ui"
import { AuthAction, AuthActionType } from "types/auth"
import { WizardAction, WizardActionType } from "types/wizard"

/**
 * WIZARD REDUCER
 */
export default function wizardReducer(state = initialState.wizard, action: WizardAction | AuthAction) {
  switch (action.type) {
    case WizardActionType.SET_WIZARD_STEP:
      return {
        ...state,
        [action.slice]: {
          ...(state?.[action.slice] || {}),
          currentStep: action.step,
        },
      }
    case WizardActionType.SELECT_WIZARD_ITEM:
      return {
        ...state,
        [action.slice]: {
          ...(state?.[action.slice] || {}),
          [action.step]: {
            ...(state?.[action.slice]?.[action.step] || {}),
            [action.name]: isDefined(action.toggleValue)
              ? Boolean(action.toggleValue)
              : Boolean(!state?.[action.slice]?.[action.step]?.[action.name]),
          },
        },
      }
    case WizardActionType.UPDATE_WIZARD_ITEM:
      return {
        ...state,
        [action.slice]: {
          ...(state?.[action.slice] || {}),
          [action.step]: {
            ...(state?.[action.slice]?.[action.step] || {}),
            [action.name]: action.value,
          },
        },
      }
    case WizardActionType.CLEAR_WIZARD_STATE:
      return {
        ...state,
        [action.slice]: {
          ...initialState.wizard?.[action.slice],
        },
      }
    case WizardActionType.SET_WIZARD_ERROR:
      return {
        ...state,
        [action.slice]: {
          ...(state?.[action.slice] || {}),
          [action.step]: {
            ...(state?.[action.slice]?.[action.step] || {}),
            error: action.error,
          },
        },
      }
    case WizardActionType.CLEAR_WIZARD_ERROR:
      return {
        ...state,
        [action.slice]: {
          ...(state?.[action.slice] || {}),
          [action.step]: {
            ...(state?.[action.slice]?.[action.step] || {}),
            error: null,
          },
        },
      }
    case AuthActionType.SIGN_USER_OUT:
      return initialState.wizard
    default:
      return state
  }
}

/**
 * Wizard Actions
 */

/* Used to toggle between values in the redux wizard form */
export type SelectWizardParams = { slice: string; step: string; name: string; toggleValue: any }
export type SelectWizardResult = ThunkAction<WizardAction, StateShape, void, WizardAction>
const selectWizardItem = ({ slice, step, name, toggleValue }: SelectWizardParams): SelectWizardResult => {
  return (dispatch) => {
    return dispatch({ type: WizardActionType.SELECT_WIZARD_ITEM, slice, step, name, toggleValue })
  }
}

/* Used to enter new inputs or update an existing input in the redux wizard form */
export type UpdateWizardItemParams = { slice: string; step: string; name: string; value: any }
export type UpdateWizardItemResult = ThunkAction<WizardAction, StateShape, void, WizardAction>
const updateWizardItem = ({ slice, step, name, value }: UpdateWizardItemParams): UpdateWizardItemResult => {
  return (dispatch) => {
    return dispatch({ type: WizardActionType.UPDATE_WIZARD_ITEM, slice, step, name, value })
  }
}

/* Used to move between one stage of the form and another */
export type SetWizardStepParams = { slice: string; step: string }
export type SetWizardStepResult = ThunkAction<WizardAction, StateShape, void, WizardAction>
const setWizardStep = ({ slice, step }: SetWizardStepParams): SetWizardStepResult => {
  return (dispatch) => {
    return dispatch({ type: WizardActionType.SET_WIZARD_STEP, slice, step })
  }
}

/* Clear the entire slice of wizard state from redux to initial state */
export type ClearWizardParams = { slice: string }
export type ClearWizardResult = ThunkAction<WizardAction, StateShape, void, WizardAction>
const clearWizardState = ({ slice }: ClearWizardParams): ClearWizardResult => {
  return (dispatch) => {
    return dispatch({ type: WizardActionType.CLEAR_WIZARD_STATE, slice })
  }
}

/* Clear an error for a particular step in a given wizard slice */
export type ClearWizardErrorParams = { slice: string; step: string }
export type ClearWizardErrorResult = ThunkAction<WizardAction, StateShape, void, WizardAction>
const clearWizardError = ({ slice, step }: ClearWizardErrorParams): ClearWizardErrorResult => {
  return (dispatch) => {
    return dispatch({ type: WizardActionType.CLEAR_WIZARD_ERROR, slice, step })
  }
}

/* Set an error for a particular slice of wizard state on a particular step */
export type SetWizardErrorParams = { slice: string; step: string; error: string }
export type SetWizardErrorResult = ThunkAction<WizardAction, StateShape, void, WizardAction>
const setWizardError = ({ slice, step, error }: SetWizardErrorParams): SetWizardErrorResult => {
  return (dispatch) => {
    const errorMessages = extractErrorMessages(error)

    errorMessages.forEach((error) => {
      const toast = {
        title: "Error",
        contents: makeUserFriendlyErrorMessage(error),
        type: ToastType.DANGER,
        link: error === EMAIL_NEEDS_CONFIRMATION_MESSAGE ? `/profile/` : null,
      }

      dispatch(uiActions.addUiToast({ toast }))
    })

    return dispatch({
      type: WizardActionType.SET_WIZARD_ERROR,
      slice,
      step,
      error,
    }) as WizardAction
  }
}

export const Actions = {
  selectWizardItem,
  updateWizardItem,
  setWizardStep,
  clearWizardState,
  clearWizardError,
  setWizardError,
}
