import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import localForage from "localforage"

import authReducer from "models/auth"
import attendanceTrackingReducer from "models/personalization/attendanceTracking"
import eventsReducer from "models/events"
import favoritesReducer from "models/personalization/favorites"
import feedReducer from "models/feed"
import notificationsReducer from "models/notifications"
import profilesReducer from "models/profiles"
import providersReducer from "models/providers"
import reviewsReducer from "models/reviews"
import searchReducer from "models/search"
import wizardReducer from "models/wizard"
import uiReducer from "models/ui"

const authPersistConfig = {
  key: "auth",
  storage: localForage,
  blacklist: ["error"],
}
const attendanceTrackingPersistConfig = {
  key: "attendanceTracking",
  storage: localForage,
  blacklist: ["error"],
}
const eventsPersistConfig = {
  key: "events",
  storage: localForage,
  blacklist: ["error"],
}
const favoritesPersistConfig = {
  key: "favorites",
  storage: localForage,
  blacklist: ["error"],
}
const notificationsPersistConfig = {
  key: "notifications",
  storage: localForage,
  blacklist: ["error"],
}
notificationsReducer
const providersPersistConfig = {
  key: "providers",
  storage: localForage,
  blacklist: ["error"],
}
const profilesPersistConfig = {
  key: "profiles",
  storage: localForage,
  blacklist: ["error"],
}
const reviewsPersistConfig = {
  key: "reviews",
  storage: localForage,
  blacklist: ["error"],
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  attendanceTracking: persistReducer(attendanceTrackingPersistConfig, attendanceTrackingReducer),
  events: persistReducer(eventsPersistConfig, eventsReducer),
  favorites: persistReducer(favoritesPersistConfig, favoritesReducer),
  feed: feedReducer,
  notifications: persistReducer(notificationsPersistConfig, notificationsReducer),
  profiles: persistReducer(profilesPersistConfig, profilesReducer),
  providers: persistReducer(providersPersistConfig, providersReducer),
  reviews: persistReducer(reviewsPersistConfig, reviewsReducer),
  search: searchReducer,
  ui: uiReducer,
  wizard: wizardReducer,
})

export default rootReducer
