import { ID, Timestamp, Username, Slug } from "types/common"
import { UserID } from "types/user"
import { EventID } from "types/events"
import { ReviewID } from "types/reviews"
import { ProviderID } from "types/providers"
/**
 * FEED TYPES
 *
 */
export type FeedType = "main" | "providers" | "events" | "reviews"
export type FeedActivityItemID = ID
export type FeedActivitySourceType = "user" | "event" | "provider"
export type FeedActivityTargetType = "user" | "event" | "provider" | "review" | "post"
export type FeedActivityType =
  | "like"
  | "favorite"
  | "attended_event"
  | "registered_for_event"
  | "left_review"
  | "claim"
  | "created_event"
  | "hosted_event"
  | "presented"
  | "created_post"
  | "joined_team"
  | "created_provider"
export interface FeedActivityItemBase {
  id: FeedActivityItemID
  activity_source: FeedActivitySourceType
  activity_target: FeedActivityTargetType
  activity_type: FeedActivityType
  user_id?: UserID
  event_id?: EventID
  review_id?: ReviewID
  provider_id?: ProviderID
  // post_id?: PostID
  created_at: Timestamp
  updated_at: Timestamp
}
export interface FeedActivityItemTruncated extends FeedActivityItemBase {}
export interface FeedActivityItemFull extends FeedActivityItemBase {
  username?: Username
  is_social?: boolean
  image?: string
  imgix_src?: string
  event_name?: string
  event_slug?: Slug
  provider_name?: string
  provider_slug?: Slug
  review_headline?: string
  review_comment?: string
}
export type FeedActivityItem = FeedActivityItemTruncated | FeedActivityItemFull
export interface FeedItem {
  activity_item: FeedActivityItem
}
export interface FeedState {
  lastFetched: Timestamp | null
  isLoading: boolean
  error: Error
  data: Record<FeedActivityItemID, FeedActivityItem>
  hasNext: Record<FeedType, boolean>
}

export enum FeedActionType {
  CLEAR_FEED_STATE = "@@feed/CLEAR_FEED_STATE",
  SET_HAS_NEXT_FOR_FEED = "@@feed/SET_HAS_NEXT_FOR_FEED",

  FETCH_MAIN_FEED_FOR_USER = "@@feed/FETCH_MAIN_FEED_FOR_USER",
  FETCH_MAIN_FEED_FOR_USER_SUCCESS = "@@feed/FETCH_MAIN_FEED_FOR_USER_SUCCESS",
  FETCH_MAIN_FEED_FOR_USER_FAILURE = "@@feed/FETCH_MAIN_FEED_FOR_USER_FAILURE",
}

// GENERIC FEED
export interface IClearFeedState {
  type: FeedActionType.CLEAR_FEED_STATE
}
export interface ISetHasNextForFeed {
  type: FeedActionType.SET_HAS_NEXT_FOR_FEED
}
export type GenericFeedAction = IClearFeedState | ISetHasNextForFeed

// FETCH MAIN FEED
export interface IFetchMainFeedForUser {
  type: FeedActionType.FETCH_MAIN_FEED_FOR_USER
}
export interface IFetchMainFeedForUserSuccess {
  type: FeedActionType.FETCH_MAIN_FEED_FOR_USER_SUCCESS
  data: FeedItem[]
}
export interface IFetchMainFeedForUserFailure {
  type: FeedActionType.FETCH_MAIN_FEED_FOR_USER_FAILURE
  error: Error
}
export type FetchMainFeedAction = IFetchMainFeedForUser | IFetchMainFeedForUserSuccess | IFetchMainFeedForUserFailure

export type FeedAction = GenericFeedAction | FetchMainFeedAction
