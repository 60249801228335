import styled from "styled-components"
import { Footer, Navbar, Spacer } from "components"
import { DataExplorerKeyPad } from "views"
import BaseLayout from "../Base/Base"

const DataExplorerWrapper = styled.div`
  background: var(--background-color-lightest-blue);
  height: 100%;
  min-height: 120vh;
`
const DataExplorerContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: var(--mobile-width);
`
const DataExplorerTitle = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 3.5rem;
  width: 100%;
`

export default function DataExplorerLayout({ children, ...props }) {
  return (
    <BaseLayout>
      <Navbar />
      <DataExplorerWrapper>
        <DataExplorerContainer>
          <Spacer size="xxl" />
          <DataExplorerTitle>Explore Public Data</DataExplorerTitle>
          <Spacer size="xl" />
          <DataExplorerKeyPad />

          <div id="viz">
            <Spacer size="xxxl" />
            <>{children}</>
          </div>
          <Spacer size="xl" />
        </DataExplorerContainer>
      </DataExplorerWrapper>
      <Footer />
    </BaseLayout>
  )
}
